import React from 'react'
import { Modal,Button} from 'react-bootstrap';
import { useParams } from 'react-router-dom';
function PrivacyPolicy(props) {
	const {id} = useParams();
	const showPrivacy = id === "1" || props.showPrivacy;
	const PrivacyPolicyPage = () => {
		window.open('/PrivacyPolicy/1', '_blank');
	  }
  return (
    <Modal
        show={showPrivacy}
        onHide={props.handlePrivacyClose}
        className='terms-privacypolicy'
        size="xl"
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Header closeButton>
          <Modal.Title><h5 className="modal-title" onClick={PrivacyPolicyPage}>Privacy Policy</h5></Modal.Title>
        </Modal.Header>
        <Modal.Body className="terms-container">
			<h4 class="mt-0">Website Information</h4>
			<p>Our website, <b>SaveDate</b>, can be accessed at <a href="https://savedate.me/">www.savedate.me</a>, and you can contact us through the email address <a href="mailto:info@savedate.me">info@savedate.me</a>. We provide an online invitation card service, and the most recent modification to our website was made on <b>November 10, 2023</b>.</p>

			<h4>Privacy Policy</h4>
			<h5>WHAT DO WE DO WITH YOUR INFORMATION?</h5>
			<p>When you use our app to create an event, we collect various personal details from you, including your name, phone number, address, email address, and even photos. This collected personal information serves the primary purposes of providing our services and enhancing the functionality of our website.</p>
			
			<h5>CONSENT</h5>
			<p>Obtaining your consent is a crucial aspect of our data collection process. When you provide us with your personal information during the registration process, you implicitly grant us permission to collect and use that information solely for the specific purposes you intended.</p>
			
			<h5>DISCLOSURE</h5>
			<p>Please be aware that we may disclose your personal information when obligated to do so by law or if you are found in violation of our Terms of Service.</p>
			
			<h5>THIRD-PARTY SERVICES</h5>
			<p>In general, the third-party providers we employ will collect, use, and share your information only to the extent necessary to facilitate the services they offer through our platform. Nevertheless, specific third-party service providers, particularly payment gateways and transaction processors, maintain their own distinct privacy policies concerning the information we are obligated to supply to them for purchase-related transactions. It is advisable to thoroughly review their privacy policies to understand how these providers handle your personal data. Additionally, please be mindful that certain providers might be situated in or operate from jurisdictions different from yours or ours. Consequently, should you opt to proceed with a transaction involving a third-party service provider, your information may be subject to the laws and regulations of the jurisdiction in which that provider or its facilities are located. Once you exit our website or get redirected to a third-party site or application, you are no longer governed by our Privacy Policy or our website’s Terms of Service. When you click on links within our platform, please be aware that these links might redirect you to external sites, and we recommend you carefully review their privacy statements as we are not responsible for their privacy practices.</p>
			
			<h5>SECURITY</h5>
			<p>We take the security of your personal information seriously and implement reasonable precautions. We adhere to industry best practices to ensure that your data is not inappropriately lost, misused, accessed, disclosed, altered, or destroyed.</p>
			
			<h5>COOKIES</h5>
			<p>Cookies are employed on our website to maintain your user session, ensuring a seamless user experience. However, these cookies are not used to personally identify you on other websites.</p>
			
			<h5>AGE OF CONSENT</h5>
			<p>By using our site, you confirm that you are either of the legal age of majority in your state or province of residence or have obtained the necessary consent from any minor dependents to use our services.</p>
			
			<h5>PAYMENT</h5>
			<p>As of now, we are delighted to inform you that our website is being provided free of cost, and we are not collecting any payments for a specified period. There is no need for payment processing. Your financial information is not required during this time. Our commitment to your privacy and data security remains unwavering, and we continue to adhere to high standards in this regard. If there are any future changes to our payment policy, we will notify you promptly. Thank you for choosing SaveDate for your online invitation card needs.</p>

			<h5>CHANGES TO THIS PRIVACY POLICY</h5>
			<p>We retain the right to modify this privacy policy at our discretion, and we recommend that you review it regularly. Any changes or clarifications will take effect immediately upon their posting on our website, and we will also notify you if there are any material revisions to this policy. This ensures that you remain informed about what information we collect, how we utilize it, and under what circumstances, if any, we may use or disclose it.</p>
			
			
			<h4>Questions & Contact Information</h4>
			<p>If you have any inquiries or require further assistance, please do not hesitate to reach out to us at info@savedate.me.</p>
        </Modal.Body>
      </Modal>
  )
}

export default PrivacyPolicy