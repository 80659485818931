import React from 'react';
import SavedateLogo from '../../Images/DeactivatedImages/savedate-logo-footer.svg';

function LoadingPage() {
  const containerStyle = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    minHeight: '100vh', // Ensures the content takes at least the full viewport height
  };

  const logoStyle = {
    // maxWidth: '15%',
    // maxHeight: '15%',
    width:'160px',
    height:'160px',
    zIndex: '995',
    animation: 'footerimg 6s infinite alternate',
    animationDirection: 'alternate',
    animationTimingFunction: 'ease-out'
  };


  return (
    <div style={containerStyle}>
      <img src={SavedateLogo} alt="Logo" style={logoStyle} />
    </div>
  );
}

export default LoadingPage;