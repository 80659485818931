 import { PreviewUrl } from "./BaseUrl";
 import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";


 //Status Check
 export function CheckEventStatus(date,ExpDate,status) {
    // console.log(date,ExpDate,status)
    const currentDate = new Date();
    const eventDate = new Date(date);
    const expDate = new Date(ExpDate);
    
    if (status === 2) {
      return "Deactive";
    } else if (currentDate > expDate) {
      return "Expired";
    } else if (currentDate < expDate && status===0) {
      return "Active";
    } else {
      return "Unknown"; // Handle any other cases as needed
    }
  }

  // dd/mm/yyyy
  export function formatDate(dateString) {
    const date = new Date(dateString);
    const day = date.getDate();
    const month = date.getMonth() + 1; // Months are zero-indexed
    const year = date.getFullYear();

    // Pad the day and month with leading zeros if needed
    const formattedDay = String(day).padStart(2, "0");
    const formattedMonth = String(month).padStart(2, "0");

    return `${formattedDay}/${formattedMonth}/${year}`;
  }

  // Whatsapp share

  export function WhatsappShare(SecretCode) {
    window.open(
      `https://wa.me/?text=${encodeURIComponent(
        `${PreviewUrl}/preview/${SecretCode}`
      )}`
    )
    
  }

  // Open Preview
  export function OpenPreview(SecretCode){
    window.open(PreviewUrl+`/preview/${SecretCode}`, "_blank") 
  }

  export const copyUrlToClipboard = (SecretCode) => {
    const urlToCopy = `${PreviewUrl}/preview/${SecretCode}`;
    
    // Create a temporary textarea element to copy the URL
    const tempTextArea = document.createElement("textarea");
    tempTextArea.value = urlToCopy;
    document.body.appendChild(tempTextArea);
    tempTextArea.select();
    try {
      document.execCommand("copy");
      // Display success toast notification
      toast.success("URL copied to clipboard!", {
        position: 'top-center',
        autoClose: 2000, // Adjust the time as needed
        hideProgressBar: true,
      });
    } catch (err) {
      // Display error toast notification
      toast.error("Failed to copy URL to clipboard", {
        position: 'top-center',
        autoClose: 2000, // Adjust the time as needed
        hideProgressBar: true,
      });
    }
    document.body.removeChild(tempTextArea);
  };

 export function getStatusString(status) {
  console.log('status',status);
    switch(status) {
      case 0:
        return 'Activate';
      case 1:
        return 'Delete';
      case 2:
        return 'Deactivate';
      default:
        return 'Unknown';
    }
  }