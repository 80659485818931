import React from 'react'
import { Modal,Button} from 'react-bootstrap';
import '../../Components/AdminPage Components/Css/BannerImageAlert.css'
function Alert(props) {
  return (
    <Modal
    show={props.AlertModal}
    size="sm"
    onHide={props.handleAlertClose}
    aria-labelledby="contained-modal-title-vcenter"
    centered
  >
    <Modal.Body className="alert-boxwrap" >
    <button type="button" class="close" onClick={props.handleAlertClose} >
                    <i class="fas fa-times"></i>
                </button>
                <i class="fas fa-exclamation-circle"></i>
                <h5>Warning</h5>
                <p>{props.AlertMessage}</p>
    </Modal.Body>
  </Modal>
  )
}

export default Alert