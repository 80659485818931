import React,{useState} from 'react'
import { useNavigate } from 'react-router-dom'
import '../../Website/Css/MainPage.css'
import { useLocation } from 'react-router-dom';
import { BaseUrl } from '../../Constants/BaseUrl';
import LoadingModal from '../AdminPage Components/LoadingModal';
import axios from 'axios';
import Alert from './Alert';
function DeleteAccount() {
    const navigate=useNavigate()
    const location = useLocation();
    const { DeleteEmail } = location.state || { DeleteEmail: null };
    const [DeleteOTP, setDeleteOTP] = useState('')
    const [ButtonLoading, setButtonLoading] = useState(false)
    const handleDeleteAccount=()=>{

        if(DeleteEmail&&DeleteOTP){
            showLoadingModalOpen()
            axios.get(BaseUrl+`/User?DeleteEmail=${DeleteEmail}&Password=${DeleteOTP}`)
            .then(response => {
              console.log('Delete',response.data)
              if(response.data[0]&&response.data[0].Error===0){
                localStorage.clear();
               window.location.href = '/';
              }else{
                setAlertModal(true)
                setAlertMessage('Invalid OTP.');
              }
            })
            .catch(error => {
              console.error('Error fetching data:', error);
            }) .finally(() => {
              closeLoadingModal()
            });
        }else{
            
            if (!DeleteEmail) {
                setAlertModal(true)
                setAlertMessage('Please log in to SaveDate and click the "Delete Account" button.');
            } 
            if (!DeleteOTP) {

                setAlertModal(true)
                setAlertMessage('Please enter the OTP.');
            }
        }
    }
         //Loading Modal
  const [showLoadingModal, setShowLoadingModal] = useState(false);

  const showLoadingModalOpen = () => {
    setShowLoadingModal(true);
  };

  const closeLoadingModal = () => {
    setShowLoadingModal(false);
  };
  //Alert
const [AlertMessage, setAlertMessage] = useState('')
const [AlertModal, setAlertModal] = useState(false)
const handleAlertClose=()=>{
 setAlertModal(false)
}
  return (
    <>
    <Alert AlertModal={AlertModal} handleAlertClose={handleAlertClose} AlertMessage={AlertMessage}/>
    <LoadingModal showLoadingModal={showLoadingModal} closeLoadingModal={closeLoadingModal}/>
    <div className="home-page">
    <div className="register-login-wrapper">
      <div className="register-login-middlealign">

      <button aria-label="Back Button" className="register-backbtn" onClick={()=>navigate(-1)}>
            <i className="fas fa-arrow-left"></i>
            <svg viewBox="0 0 440 440">
              <path
                className="st0"
                d="M439,220.5c0,53.7-19.3,103-51.4,141.1C347.3,409.6,287,440,219.5,440c-64.4,0-107.3-38.8-147.5-83 C36.6,318,0,277.3,0,220.5c0-62,40.7-107.6,82-147.5c39.5-38.1,78.3-72,137.5-72C281.3,1,331.1,42.9,371,84 C409.4,123.5,439,161.1,439,220.5z"
              >
                <animate
                  attributeType="XML"
                  attributeName="d"
                  dur="8s"
                  repeatCount="indefinite"
                  values="
                  M422,220c0,53.7-2.3,103.5-34.4,141.6C347.3,409.6,287,440,219.5,440c-64.4,0-125.3-31.8-165.5-76		C18.6,325,0,277.3,0,220.5c0-62,21.7-117.6,63-157.5c39.5-38.1,97.3-62,156.5-62C281.3,1,331.1,42.9,371,84		C409.4,123.5,422,160.6,422,220z;
                  M434,220c0,53.7-14.3,103.5-46.4,141.6C347.3,409.6,287,440,219.5,440c-64.4,0-106.3-51.8-146.5-96		C37.6,305,0,277.3,0,220.5c0-62,39.7-105.6,81-145.5c39.5-38.1,79.3-74,138.5-74C281.3,1,329.1,20.9,369,62		C407.4,101.5,434,160.6,434,220z;
                  M434,220c0,53.7,9.1,126.9-23,165c-40.3,48-124,34-191.5,34c-64.4,0-113.3,26.2-153.5-18		C30.6,362,0,272.8,0,216c0-62,35.7-97.1,77-137c39.5-38.1,83.8-74,143-74c61.8,0,99.1,8.9,139,50C397.4,94.5,434,160.6,434,220z;
                  M434,220c0,53.7-1.9,107.9-34,146c-40.3,48-113,74-180.5,74c-64.4,0-148.3-34.8-188.5-79		c-35.4-39-18-83.7-18-140.5c0-62,2.7-116.6,44-156.5c39.5-38.1,103.8-52,163-52c61.8,0,101.1,11.9,141,53		C399.4,104.5,434,160.6,434,220z;
                  M434,220c0,53.7-24.9,82.9-57,121c-40.3,48-90,99-157.5,99c-64.4,0-101.3-45.8-141.5-90		C42.6,311,0,272.8,0,216c0-62,29.7-106.1,71-146c39.5-38.1,89.8-65,149-65c61.8,0,117.1,2.9,157,44C415.4,88.5,434,160.6,434,220z;
                  M422,220c0,53.7-2.3,103.5-34.4,141.6C347.3,409.6,287,440,219.5,440c-64.4,0-125.3-31.8-165.5-76		C18.6,325,0,277.3,0,220.5c0-62,21.7-117.6,63-157.5c39.5-38.1,97.3-62,156.5-62C281.3,1,331.1,42.9,371,84		C409.4,123.5,422,160.6,422,220z;"
                />
              </path>
            </svg>
          </button>

    <div className="container">
    <div className="row justify-content-center">
      <div className="col-lg-5 col-md-7 ps-4 pe-4">
        <div className="phogographer-con login-form">
          <h3>Delete Account</h3>
          {DeleteEmail?<p> Complete the Verification: Retrieve the OTP Code Sent to{" "}<b>{DeleteEmail}</b> and Proceed to Verify Your SaveDate Account.</p>:
          <p>If you have any concerns or issues, please contact our support team for assistance before proceeding with this action</p>}
          <div className="row">
          {/* <div className="col-lg-12">
              <span><i className="far fa-envelope"></i>
                <input
                  type="email"
                  placeholder="Enter Your Email"
                  value={DeleteEmail}
                  onChange={(e)=>setDeleteEmail(e.target.value)}
                />
              </span>
            </div> */}

          <div className="col-lg-12">
            <span>
              <i className="fas fa-mobile-alt" />
              <input type="text" placeholder="Enter 6 Digit Code" value={DeleteOTP} onChange={(e)=>setDeleteOTP(e.target.value)}/>
            </span>
          </div>
            
            <div className="col-lg-12">
              <button aria-label="Submit Button" className={ButtonLoading?"loginbtn loader":"loginbtn"} disabled={ButtonLoading} onClick={handleDeleteAccount}>
              <font>Delete</font>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  </div>
    </div>
    <section className="footer-wrapper">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-6">
              <p>&copy; 2023 www.savedate.me. All rights reserved</p>
            </div>
            <div className="col-lg-6" >
                <a  style={{cursor:'pointer'}}>Terms &amp; Conditions</a>
                <a style={{cursor:'pointer'}}>Privacy Policy</a>
                <a style={{cursor:'pointer'}} >Contact Us</a>
              </div>
          </div>
        </div>
      </section>
  </div>
  </>
  )
}

export default DeleteAccount