import React from "react";
import Header from "../Components/AdminPage Components/Header";
import "../Pages/Page Css/AdminMainpage2.css";
import WeddingIcon from "../Images/AdminImges/img/wedding-icon.jpg";
import EngagementIcon from "../Images/AdminImges/img/engagement-icon.jpg";
import { useEffect } from "react";
import { useState } from "react";
import axios from "axios";
import { BaseUrl } from "../Constants/BaseUrl";
import {
  CheckEventStatus,
  copyUrlToClipboard,
  formatDate,
  getStatusString,
  OpenPreview,
  WhatsappShare,
} from "../Constants/Helper";
import "./Page Css/CreateEvents.css";
import { useNavigate } from "react-router";
import { format } from "date-fns";
import Alert from "../Components/UserMainPage Components/Alert";
import LoadingModal from "../Components/AdminPage Components/LoadingModal";
import { Dropdown, Modal } from "react-bootstrap";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ConfirmationModal from "../Components/AdminPage Components/ConfirmationModal";
function CreateEventsPage() {
  const navigate = useNavigate();
  const [EventsList, setEventsList] = useState([]);
  const UserDetails = JSON.parse(localStorage.getItem("user"));
  const [AlertModal, setAlertModal] = useState(false);
  const [AlertMessage, setAlertMessage] = useState("");
  const [IsFinished, setIsFinished] = useState(false);
  const ToggleIsFinished = () => {
    setIsFinished(!IsFinished);
  };
  const currentDate = new Date();
  const currentDateTimeString = format(currentDate, "MM/dd/yyyy, hh:mm:ss a");
  //confirmation Modal
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const handleCloseConfirmModal = () => setShowConfirmModal(false);
  const handleOpenConfirmModal = () => setShowConfirmModal(true);
  //Loading Modal
  const [showLoadingModal, setShowLoadingModal] = useState(false);

  const showLoadingModalOpen = () => {
    setShowLoadingModal(true);
  };

  const closeLoadingModal = () => {
    setShowLoadingModal(false);
  };
  const handleAlertClose = () => setAlertModal(false);
  useEffect(() => {
    if (UserDetails.Id) {
      showLoadingModalOpen();
      axios
        .get(BaseUrl + `/Event?UserId=${UserDetails.Id}`)
        .then((response) => {
          if (response?.data) {
            // console.log("response:", response.data);
            setEventsList(response.data);
          }
        })
        .catch((error) => {
          // Handle errors here
          console.error("Error:", error);
        })
        .finally(() => {
          closeLoadingModal();
        });
    }
  }, [IsFinished]);

  //Set Default Event
  const SetDefaultEvent = (EventId) => {
    showLoadingModalOpen();
    axios
      .get(BaseUrl + `/Event?UserId=${UserDetails.Id}&EventId=${EventId}`)
      .then((response) => {
        console.log(response.data);
        if (response.data && response.data[0].error === 0) {
          localStorage.removeItem("EventId");
          localStorage.removeItem("EventDate");
          localStorage.removeItem("EventName");
          localStorage.removeItem("SecretCode");
          navigate("/wedding-details");
        } else {
          setAlertMessage("Something went wrong!");
          setAlertModal(true);
        }
      })
      .catch((error) => {
        // Handle error
        console.error("Error:", error);
      })
      .finally(() => {
        closeLoadingModal();
      });
  };
  //Create Invitation

  const CreateEvent = () => {
    // const confirmed = window.confirm(
    //   "Are you sure you want to create a new event?"
    // );
    // if (!confirmed) {
    //   return; // Don't create event if user cancels
    // }
    let InvitationDetails = {
      eventDate: currentDateTimeString,
      brideFirstName: "",
      brideLastName: "",
      groomFirstName: "",
      groomLastName: "",
      phoneNumber: "",
      Email: UserDetails.Email,
      brideFirstLetter: "",
      groomFirstLetter: "",
      eventSubEventDetailsList: [],
      eventGalleryList: [],
      code: "",
      creatorId: UserDetails.Id,
      SocialLoginNum: 1,
      CreaterName: UserDetails.Name,
    };
    showLoadingModalOpen();
    axios
      .post(BaseUrl + "/CreateInvitation", InvitationDetails)
      .then((response) => {
        console.log(response.data.Err);
        if (
          response.data &&
          response.data.EventId !== 0 &&
          response.data.Error == 111
        ) {
          SetDefaultEvent(response.data.EventId);
        } else {
          setAlertMessage("Error while creating Event");
          setAlertModal(true);
        }
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        closeLoadingModal();
      });
  };

  // Deactivate,Delete,Activate
  const [StatusChangeData, setStatusChangeData] = useState({
    EventId: null,
    StatusValue: 0,
  });
  const [StatusConfirmModal, setStatusConfirmModal] = useState(false);
  const CloseStatusModal = () => {
    setStatusConfirmModal(false);
  };
  const OpenStatusModal = () => {
    setStatusConfirmModal(true);
  };
  const StatuClick = (EventId, action) => {
    let statusValue;

    switch (action) {
      case "deactivate":
        statusValue = 2;
        break;
      case "delete":
        statusValue = 1;
        break;
      case "activate":
        statusValue = 0;
        break;
      default:
        throw new Error("Invalid action");
    }
    
    setStatusChangeData({
      EventId: EventId,
      StatusValue: statusValue,
    });
    OpenStatusModal()
  };

  const StatusChange = () => {
    CloseStatusModal()
    showLoadingModalOpen();
    axios
      .get(BaseUrl + `/Event?DelEventId=${StatusChangeData.EventId}&Status=${StatusChangeData.StatusValue}`)
      .then((response) => {
        if (response?.data) {
              toast.success(`Event ${getStatusString(StatusChangeData.StatusValue)}d!`, {
                position: "top-center",
              });
        }
        
        ToggleIsFinished();
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
        closeLoadingModal();
      });
  };

  return (
    <>
      <ConfirmationModal
        show={showConfirmModal}
        close={handleCloseConfirmModal}
        Confirm={CreateEvent}
        message="Are you sure you want to create a new event?"
      />
      <LoadingModal
        showLoadingModal={showLoadingModal}
        closeLoadingModal={closeLoadingModal}
      />
      <Alert
        AlertModal={AlertModal}
        handleAlertClose={handleAlertClose}
        AlertMessage={AlertMessage}
      />
      <body class="bodybg">
        <Header />
        <div className="list-create-button">
          <div className="container-fluid">
            <div className="row justify-content-center">
              <div className="col-lg-4">
                <button
                  className="create-button"
                  onClick={handleOpenConfirmModal}
                >
                  Create New
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="events-list-wrapper">
          <div className="container-fluid">
            <div className="row events-row justify-content-center">
              {EventsList?.map((data, i) => (
                <div className="col-xl-3 col-lg-4 col-md-6" key={i}>
                  <div
                    className={`event-item-div ${CheckEventStatus(
                      data.EventDate,
                      data.ExpiryDate,
                      data.Status
                    )}`}
                  >
                    <div className="event-item-header">
                      <div className="row">
                        <div className="col-10">
                          <h5>
                            <span>{data.EventName}</span>
                          </h5>
                          <h6>
                            {CheckEventStatus(
                              data.EventDate,
                              data.ExpiryDate,
                              data.Status
                            )}
                          </h6>
                          {/* <button className="notification-icon">
                        <span>12</span>
                        <i className="fas fa-bell" />
                      </button> */}
                        </div>
                        <div className="col-2">
                          <Dropdown>
                            <Dropdown.Toggle
                              className="dropdownicon"
                              id="dropdownMenuButton1"
                            >
                              <i className="fas fa-ellipsis-h" />
                            </Dropdown.Toggle>
                            <Dropdown.Menu aria-labelledby="dropdownMenuButton1">
                              <Dropdown.Item
                                onClick={() =>
                                  StatuClick(
                                    data.Id,
                                    data.Status === 0
                                      ? "deactivate"
                                      : "activate"
                                  )
                                }
                              >
                                <i className="fas fa-ban" />
                                {data.Status === 0 ? "Deactivate" : "Activate"}
                              </Dropdown.Item>
                              {EventsList?.length !== 1 && (
                                <Dropdown.Item
                                  onClick={() =>
                                    StatuClick(data.Id, "delete")
                                  }
                                >
                                  <i className="fas fa-trash-alt" /> Delete
                                </Dropdown.Item>
                              )}
                            </Dropdown.Menu>
                          </Dropdown>
                        </div>
                      </div>
                    </div>
                    <div className="event-item-content">
                      <div className="event-icon-con">
                        <img
                          src={
                            data.EventName === "Engagement"
                              ? EngagementIcon
                              : WeddingIcon
                          }
                          alt="SaveDate"
                        />
                      </div>
                      <div className="event-item-middlealign">
                        <h4>
                        {data.BrideFName ? data.BrideFName : "Bride"} &amp; {data.GroomFName ? data.GroomFName : "Groom"}
                        </h4>
                        <h6>
                          Event Date: <b>{data.EventDate?formatDate(data.EventDate):'dd/mm/yyyy'}</b>
                        </h6>
                      </div>
                    </div>
                    <div className="event-item-footer">
                      <div className="row">
                        <div className="col-3">
                          <button onClick={() => OpenPreview(data.SecretCode)}>
                            <i className="fas fa-eye" /> View
                          </button>
                        </div>
                        <div className="col-3">
                          <button
                            onClick={() => WhatsappShare(data.SecretCode)}
                          >
                            <i className="fab fa-whatsapp" /> Share
                          </button>
                        </div>
                        <div className="col-3 ">
                          <button
                            onClick={() => copyUrlToClipboard(data.SecretCode)}
                          >
                            <i className="fas fa-link" /> Copy
                          </button>
                        </div>
                        <div
                          className={
                            data.dId === 1 ? "col-3 DefaultEvent" : "col-3"
                          }
                        >
                          <button onClick={() => SetDefaultEvent(data.Id)}>
                            <i className="fas fa-edit" /> Edit
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </body>
      <Modal
        show={StatusConfirmModal}
        size="sm"
        onHide={CloseStatusModal}
        centered
      >
        <Modal.Body className="alert-boxwrap updatecancel-btn">
          <button
            type="button"
            className="close"
            onClick={() => CloseStatusModal()}
          >
            <i className="fas fa-times"></i>
          </button>
          <i class="fas fa-exclamation-circle"></i>
          <h5>Warning</h5>
          <p>{`Are you sure you want to ${getStatusString(StatusChangeData.StatusValue)} this event?`}</p>
          <div className="row">
            <div className="col-6">
              <button className="cancelbtn" onClick={() => CloseStatusModal()}>
                Cancel
              </button>
            </div>
            <div className="col-6">
              <button
                className="updatebtn"
                onClick={() => StatusChange()}
              >{getStatusString(StatusChangeData.StatusValue)}</button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default CreateEventsPage;
