import React from 'react'
import { Modal, Spinner } from "react-bootstrap";
function LoadingModal(props) {
  return (
    <Modal show={props.showLoadingModal} onHide={props.closeLoadingModal} size="sm" backdrop="static" keyboard={false} centered>
      <Modal.Body className="d-flex flex-column align-items-center">
    <Spinner animation="border"  style={{ color: '#ee6e84' }} />
    <span style={{ marginTop: "10px" }}>Loading...</span>
  </Modal.Body>
    </Modal>
  )
}

export default LoadingModal