import React, { lazy, Suspense,useEffect,useState } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import LoadingPage from './Components/AdminPage Components/LoadingPage';
import DeleteAccount from './Components/UserMainPage Components/DeleteAccount';
import PrivacyPolicy from './Components/UserMainPage Components/PrivacyPolicy';
import TermsAndConditions from './Components/UserMainPage Components/TermsAndConditions';
import axios from 'axios';
import { BaseUrl,FrontEndVersion } from './Constants/BaseUrl';
import CreateEventsPage from './Pages/CreateEventsPage';
const Mainpage = lazy(() => import('./Website/Mainpage'));
const AdminMainpage2 = lazy(() => import('./Pages/AdminMainpage2'));
const Preview = lazy(() => import('./Components/AdminPage Components/Preview/Preview'));
const Error404Page = lazy(() => import('./Components/AdminPage Components/Error404Page'));
const AdminDashboard = lazy(() => import('./AdminDashboard/AdminDashboard'));
const SampleTheme1 = lazy(() => import('./Theme1Sample/SampleTheme1'));

function App() {
  useEffect(() => {
    axios.get(BaseUrl+`/AppVersion`)
    .then(response => {     
      if (response.data&&response.data.Version) {
        if (FrontEndVersion!==response.data.Version) {
          window.location.reload(true)
        }
      }
    })
    .catch(error => {
      console.error('Error fetching data:', error);
    })
  }, [])
  return (
    <div className="App">
      <Router>
        <Routes>

          <Route
            path="/"
            element={
              <Suspense fallback={<LoadingPage/>}>
                <Mainpage />
              </Suspense>
            }
          />

          <Route
            path="/wedding-details"
            element={
              <Suspense fallback={<LoadingPage/>}>
                <AdminMainpage2 />
              </Suspense>
            }
          />

          <Route
            path="/preview/:id"
            element={
              <Suspense fallback={<LoadingPage/>}>
                <Preview />
              </Suspense>
            }
          />

           <Route
            path="/Theme/:ThemeNo/:id"
            element={
              <Suspense fallback={<LoadingPage/>}>
                <Preview />
              </Suspense>
            }
          />

          <Route
            path="*"
            element={
              <Suspense fallback={<LoadingPage/>}>
                <Error404Page />
              </Suspense>
            }
          />

          <Route
            path="/Theme1Sample"
            element={
              <Suspense fallback={<LoadingPage/>}>
                <SampleTheme1 />
              </Suspense>
            }
          />

          <Route
            path="/Admin"
            element={
              <Suspense fallback={<LoadingPage/>}>
                <AdminDashboard />
              </Suspense>
            }
          />

           <Route
            path="/PrivacyPolicy/:id"
            element={
              <Suspense fallback={<LoadingPage/>}>
                <PrivacyPolicy />
              </Suspense>
            }
          />

            <Route
            path="/TermsAndConditions/:id"
            element={
              <Suspense fallback={<LoadingPage/>}>
                <TermsAndConditions/>
              </Suspense>
            }
          />

          <Route
            path="/DeleteAccount"
            element={
              <Suspense fallback={<LoadingPage/>}>
                <DeleteAccount />
              </Suspense>
            }
          />

          <Route
            path="/CreateEvents"
            element={
              <Suspense fallback={<LoadingPage/>}>
                <CreateEventsPage />
              </Suspense>
            }
          />

        </Routes>
        
      </Router>
    </div>
  );
}

export default App;
