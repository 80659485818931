import React, { useState, useRef } from "react";
import HeaderIcon from "../../Images/AdminImges/img/savedate-icon.svg";
import { Modal} from "react-bootstrap";
import axios from "axios";
import { BaseUrl, FrontEndVersion } from "../../Constants/BaseUrl";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useEffect } from "react";
import DeleteConfirmationModal from "./DeleteConfirmationModal";
import ConfirmationModal from "./ConfirmationModal";
import LoadingModal from "./LoadingModal";
import { useNavigate } from "react-router-dom";
import DeleteAccountConfirmation from "./DeleteAccountConfirmation";
function Header(props) {
  const navigate = useNavigate();
  const Eventid = JSON.parse(localStorage.getItem("EventId"));
  const EventName = JSON.parse(localStorage.getItem("EventName"));
  const [sidebarVisible, setSidebarVisible] = useState(false);
  const [reportProblemModalVisible, setReportProblemModalVisible] =
    useState(false);
  const [feedbackModal, setFeedbackModal] = useState(false);
  const [activationStatus, setActivationStatus] = useState("Active");
  useEffect(() => {
    if (props.EventData && props.EventData.length > 0) {
      const eventStatus = props.EventData[0].Status;
      if (eventStatus === 0) {
        setActivationStatus("Active");
      } else if (eventStatus === 2) {
        setActivationStatus("Deactive");
      }
    }
  }, [props.EventData]);
  const openFeedbackModal = () => {
    setFeedbackModal(true);
  };

  const closeFeedbackModal = () => {
    setFeedbackModal(false);
  };

  function handleToggleSidebar() {
    if (EventName) {
      setSidebarVisible(!sidebarVisible);
    } else {
      props.handleAlertOpen();
    }
  }

  function handleLogout() {
    localStorage.clear();
    window.location.href = "/";
  }

  function handleReportProblemModal() {
    setReportProblemModalVisible(true);
  }
  //Report propblem
  const [selectedImage, setSelectedImage] = useState("");
  const [Description, setDescription] = useState("");
  const [FeedbackDesc, setFeedbackDesc] = useState("");
  const fileInputRef = useRef(null);

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setSelectedImage(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleButtonClick = () => {
    fileInputRef.current.click();
  };
  const Refresh = () => {
    setDescription("");
    setSelectedImage("");
    setFeedbackDesc("");
  };
  const ReportProblem = (x) => {
    let ReportProblemData;
    if (x === 1) {
      ReportProblemData = {
        Description,
        ImageUrl: selectedImage,
        Type: 1,
        UserId: props.CreaterId,
      };
    } else {
      ReportProblemData = {
        Description: FeedbackDesc,
        ImageUrl: "",
        Type: 2,
        UserId: props.CreaterId,
      };
    }
    showLoadingModalOpen();
    axios
      .post(BaseUrl + "/Feedback", ReportProblemData)
      .then((response) => {
        // console.log( response.data);
        setReportProblemModalVisible(false);
        closeFeedbackModal();
        if (
          response.data &&
          response.data.Error === 0 &&
          response.data.Id > 0
        ) {
          if (ReportProblemData.Type === 1) {
            toast.success("Problem Reported successfully!", {
              position: "top-center", // Set the position to top-center
            });
          } else if (ReportProblemData.Type === 2) {
            toast.success("Thank you for your feedback!", {
              position: "top-center", // Set the position to top-center
            });
          }
          Refresh();
        } else {
          if (ReportProblemData.Type === 1) {
            toast.error("Problem Reporting failed!", {
              position: "top-center", // Set the position to top-center
            });
          } else if (ReportProblemData.Type === 2) {
            toast.error("Feedback Reporting failed!", {
              position: "top-center", // Set the position to top-center
            });
          }
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      })
      .finally(() => {
        closeLoadingModal();
      });
  };

  const Deactivate = (id) => {
    showLoadingModalOpen();
    axios
      .get(BaseUrl + `/Event?DelEventId=${Eventid}&Status=2`)
      .then((response) => {
        toast.success("SaveDate Deactivated!", {
          position: "top-center", // Set the position to top-center
        });
        window.location.reload();
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
        closeLoadingModal();
      });
  };
  function Activate() {
    showLoadingModalOpen();
    axios
      .get(BaseUrl + `/Event?DelEventId=${Eventid}&Status=0`)
      .then((response) => {
        toast.success("SaveDate Activated!", {
          position: "top-center", // Set the position to top-center
        });
        window.location.reload();
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
        closeLoadingModal();
      });
  }

  //Confirmation modal
  const [showConfirmModal, setShowConfirmModal] = useState(false);

  const handleCloseConfirmModal = () => setShowConfirmModal(false);
  const handleShowConfirmModal = () => setShowConfirmModal(true);

  //Expire Date
  function formatDate(dateString) {
    const date = new Date(dateString);
    const day = date.getDate();
    const month = date.getMonth() + 1; // Months are zero-indexed
    const year = date.getFullYear();

    // Pad the day and month with leading zeros if needed
    const formattedDay = String(day).padStart(2, "0");
    const formattedMonth = String(month).padStart(2, "0");

    return `${formattedDay}/${formattedMonth}/${year}`;
  }
  const [ExpireDate, setExpireDate] = useState("");
  useEffect(() => {
    axios
      .get(BaseUrl + `/Event?EventId=${Eventid}&dummy=0`)
      .then((response) => {
        // console.log(response.data)
        setExpireDate(response.data[0].ExpDate);
      })
      .catch((error) => console.error(error));
  }, [Eventid]);

  //Bell icon-Greetings
  const [GreetingData, setGreetingData] = useState([]);
  const [PageViewedFinished, setPageViewedFinished] = useState(false);
  const togglePageViewedFinished = () => {
    setPageViewedFinished(!PageViewedFinished);
  };
  useEffect(() => {
    axios
      .get(BaseUrl + `/EventGreetings?eventid=${Eventid}`)
      .then((response) => {
        if (response.data) {
          const filteredArray = response.data.filter((obj) => obj.Status !== 2);
          setGreetingData(filteredArray);
        }
      })
      .catch((error) => console.error(error));
  }, [PageViewedFinished]);
  //Viewed page(notifiction icon)
  useEffect(() => {
    if (props.activeTab == "greetings") {
      axios
        .get(BaseUrl + `/EventGreetings?GreetingsEventId=${Eventid}`)
        .then((response) => {
          togglePageViewedFinished();
        })
        .catch((error) => {
          console.error(error);
        });
    }
  }, [props.activeTab]);

  //Delete Confirmation modal
  const [showDeleteModal, setshowDeleteModal] = useState(false);

  const handleCloseDeleteModal = () => setshowDeleteModal(false);
  const handleShowDeleteModal = () => {
    setshowDeleteModal(true);
  };
  //Loading Modal
  const [showLoadingModal, setShowLoadingModal] = useState(false);

  const showLoadingModalOpen = () => {
    setShowLoadingModal(true);
  };

  const closeLoadingModal = () => {
    setShowLoadingModal(false);
  };
  //side menubar close
  const sidebarRef = useRef(null);

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleClickOutside = (event) => {
    if (
      sidebarRef.current &&
      !sidebarRef.current.contains(event.target) &&
      event.target.id !== "closeButton"
    ) {
      setSidebarVisible(false);
    }
  };
  //Delete Account

  const [showDeleteAccountModal, setshowDeleteAccountModal] = useState(false);

  const handleCloseDeleteAccountModal = () => setshowDeleteAccountModal(false);
  const handleShowDeleteAccontModal = () => {
    setshowDeleteAccountModal(true);
  };
  const HandleAccountDelete = () => {
    const UserDetails = JSON.parse(localStorage.getItem("user"));
    const DeleteEmail = UserDetails.Email;
    if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(DeleteEmail)) {
      showLoadingModalOpen();
      axios
        .get(BaseUrl + `/User?forgotUserName=${DeleteEmail}&check=${1}`)
        .then((response) => {
          // console.log('Login',response.data)
          if (response.data && response.data.Id > 0) {
            toast.success("OTP sent Successfully!", {
              position: "top-center",
              autoClose: 2000,
            });
            navigate("/DeleteAccount", { state: { DeleteEmail: DeleteEmail } });
          } else {
            console.log("User not exist!");
          }
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
        })
        .finally(() => {
          closeLoadingModal();
        });
    } else {
      console.log(DeleteEmail, "Invalid Email");
    }
  };
  return (
    <>
      <DeleteAccountConfirmation
        show={showDeleteAccountModal}
        close={handleCloseDeleteAccountModal}
        Confirm={HandleAccountDelete}
        message="Are you absolutely certain you wish to permanently delete your account? This action is irreversible and will result in the complete removal of all your data, including your profile information, preferences, and any associated content. You will lose access to your account and all related services. Please consider that this process cannot be undone."
      />
      <LoadingModal
        showLoadingModal={showLoadingModal}
        closeLoadingModal={closeLoadingModal}
      />
      <ConfirmationModal
        show={showDeleteModal}
        close={handleCloseDeleteModal}
        Confirm={handleLogout}
        message="Are you sure you want to log out?"
      />
      <DeleteConfirmationModal
        show={showConfirmModal}
        close={handleCloseConfirmModal}
        message={"Are you sure you want to deacitivate savedate?"}
        Delete={Deactivate}
        From="Header"
      />
      <div className="header-wrapper-admin">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-6 col-md-6 col-5">
              <img
                src={HeaderIcon}
                className="cv-reg-taglahlogo"
                alt="SaveDate"
              />
            </div>
            <div className="col-lg-6 col-md-6 col-7">
              {window.location.pathname !== "/wedding-details" && (
                <button onClick={() => navigate("/wedding-details")}>
                  <i className="fas fa-home"></i>
                </button>
              )}
              {window.location.pathname === "/wedding-details" &&(
                <button
                  className={`notification-bell${
                    GreetingData.length > 0 ? " active" : ""
                  }`}
                  onClick={() => props.handleTabSelect("greetings")}
                >
                  <i className="far fa-bell"></i>
                </button>
              )}

              <button
                className="notification-bell"
                id="closeButton"
                onClick={handleToggleSidebar}
              >
                <i
                  id="closeButton"
                  className={`fas ${sidebarVisible ? "fa-times" : "fa-bars"}`}
                ></i>
              </button>
            </div>
          </div>
        </div>
      </div>
      {sidebarVisible && (
        <div className="right-sidebar videsidebar" ref={sidebarRef}>
          <ul>
            {window.location.pathname === "/wedding-details" && (
              <li>
                <button onClick={() => navigate("/CreateEvents")}>
                  Events
                </button>
              </li>
            )}
            {/* {activationStatus === "Deactive" && (
              <li>
                <button onClick={Activate}>Activate Savedate</button>
              </li>
            )}
            {activationStatus === "Active" && (
              <li>
                <button onClick={() => handleShowConfirmModal()}>
                  Deactivate Savedate
                </button>
              </li>
            )} */}
            <li>
              <button onClick={handleReportProblemModal}>
                Report a Problem
              </button>
            </li>
            <li>
              <button onClick={openFeedbackModal}>Write your Feedback</button>
            </li>
            <li>
              <button onClick={handleShowDeleteAccontModal}>
                Delete Account
              </button>
            </li>
            <li>
              <button onClick={handleShowDeleteModal}>Logout</button>
            </li>
          </ul>
          <h6>
            SaveDate Expires on <br />
            <b>{formatDate(ExpireDate)}</b>
          </h6>
          <p
            const
            style={{
              position: "absolute",
              left: "0px",
              bottom: "-15px",
              width: "100%",
              color: "#f2c8d2",
              fontWeight: "normal",
              textAlign: "center",
              fontSize: "8px",
              lineHeight: "13px",
              padding: "15px 0px",
              margin: "0",
            }}
          >
            {FrontEndVersion}
          </p>
        </div>
      )}
      <Modal
        id="reportaproblem"
        centered
        show={reportProblemModalVisible}
        onHide={() => setReportProblemModalVisible(false)}
        className="addproduct-modal"
      >
        <Modal.Header>
          <h5 className="modal-title">Report a Problem</h5>
          <button
            type="button"
            className="close"
            onClick={() => setReportProblemModalVisible(false)}
          >
            <span>&times;</span>
          </button>
        </Modal.Header>
        <Modal.Body>
          <div className="row justify-content-center">
            <div className="col-lg-5 col-7">
              <div className="upload-photo">
                {/* Load image inside span tag to make a quick preview in modal */}
                {selectedImage && selectedImage.length > 0 && (
                  <span className="add-productpic">
                    <img src={selectedImage} alt="savedate" />
                  </span>
                )}
                <div className="profilepic-btn">
                  {selectedImage.length === 0 && (
                    <button
                      className="edit-delete upload-btn"
                      onClick={handleButtonClick}
                    >
                      Upload Screenshot
                    </button>
                  )}
                  <input
                    type="file"
                    accept="image/*"
                    onChange={handleFileChange}
                    ref={fileInputRef}
                    style={{ display: "none" }}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-12">
              <div className="input-item">
                <label>Describe The Problem</label>
                <textarea
                  className="form-control"
                  cols="30"
                  rows="10"
                  onChange={(e) => setDescription(e.target.value)}
                  value={Description}
                />
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button
            type="button"
            class="addnew-btn"
            onClick={() => ReportProblem(1)}
          >
            Submit
          </button>
        </Modal.Footer>
      </Modal>

      <Modal
        show={feedbackModal}
        onHide={closeFeedbackModal}
        id="writefeedback"
        tabIndex="-1"
        centered
        className="addproduct-modal"
      >
        <Modal.Header>
          <h5 className="modal-title">Write your Feedback</h5>
          <button type="button" className="close" onClick={closeFeedbackModal}>
            <span>&times;</span>
          </button>
        </Modal.Header>
        <Modal.Body>
          <div className="row">
            <div className="col-lg-12">
              <div className="input-item">
                <label>Write your Feedback Here</label>
                <textarea
                  className="form-control"
                  cols="30"
                  rows="10"
                  placeholder="Share details of your own experience."
                  onChange={(e) => setFeedbackDesc(e.target.value)}
                  value={FeedbackDesc}
                ></textarea>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button
            type="button"
            className="addnew-btn"
            onClick={() => ReportProblem(2)}
          >
            Submit
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default Header;
