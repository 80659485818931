import React from 'react'
import { Modal} from 'react-bootstrap';
import { useParams } from 'react-router-dom';
function TermsAndConditions(props) {
	const {id} = useParams();
	const showTerms = id === "1" || props.showTerms;
	const TermsAndConditions = () => {
		window.open('/TermsAndConditions/1', '_blank');
	  }
  return (
    <Modal
    show={showTerms}
    onHide={props.handleTermsClose}
    className='terms-privacypolicy'
    size="xl"
    aria-labelledby="example-modal-sizes-title-lg"
  >
    <Modal.Header closeButton>
      <Modal.Title><h5 className="modal-title" onClick={TermsAndConditions}>Terms & Conditions</h5></Modal.Title>
    </Modal.Header>
    <Modal.Body className="terms-container">	
			<p>These Terms of Use ("Terms") govern your access and use of the <b>SaveDate</b> website, <b>www.savedate.me</b>, and our services. By signing up for our services via Google Sign-In, Facebook Sign-In, or email registration, you are automatically and legally bound to our Terms of Use. Your access and utilization of our website and services signify your agreement to comply with and be legally bound by these Terms. If you do not agree with any part of these Terms, please refrain from using our website and services.</p>	
			<p>Effective from <b>November 10, 2023</b>, these Terms and Conditions are designed to govern your interactions with SaveDate.</p>
			
			<h5>Introduction</h5>
			<p>The website www.savedate.me ("Site") and the services provided by SaveDate ("Company") are collectively referred to as the "Service.</p>
			<p>By accessing the Service, you ("User") agree to these Terms and the Privacy Policy.</p>

			<h5>Privacy Policy</h5>
			<p>We respect your privacy and handle your personal information as per our Privacy Policy, which is an integral part of these Terms. You can review our <a href="#"  data-bs-toggle="modal" data-bs-target="#privacypolicy">Privacy Policy</a>.</p> 
			<p>By using the Service, you consent to the terms and contents of our Privacy Policy.</p>

			<h5>Registration; Rules for User Conduct and Use of the Service</h5>
			<p>You need to be at least the age of majority to use the Service.</p>
			<p>If you are a user who signs up for the Service, you will create a personalized account that includes a unique username and a password to access the Service and to receive messages from the Company. You agree to notify us immediately of any unauthorized use of your password and/or account. The Company will not be responsible for any liabilities, losses, or damages arising out of the unauthorized use of your member name, password, and/or account.</p>

			<h5>Use Restrictions</h5>
			<p>Your permission to use the Site is conditioned upon the following use, posting, and conduct restrictions:</p>
			<p>You agree that you will not under any circumstances:</p>
			<ul>
				<li>access the Service for any reason other than your personal, non-commercial use solely as permitted by the normal functionality of the Service,</li>
				<li>collect or harvest any personal data of any user of the Site or the Service,</li>
				<li>use the Site or the Service for the solicitation of business in the course of trade or connection with a commercial enterprise;</li>
				<li>distribute any part or parts of the Site or the Service without our explicit written permission (we grant the operators of public search engines permission to use spiders to copy materials from the site for the sole purpose of creating publicly available searchable indices but retain the right to revoke this permission at any time on a general or specific basis);</li>
				<li>use the Service for any unlawful purpose or the promotion of illegal activities;</li>
				<li>attempt to, or harass, abuse, or harm another person or group;</li>
				<li>use another user’s account without permission;</li>
				<li>intentionally allow another user to access your account;</li>
				<li>provide false or inaccurate information when registering an account;</li>
				<li>interfere or attempt to interfere with the proper functioning of the Service;</li>
				<li>make any automated use of the Site, the Service or the related systems, or take any action that we deem to impose or to potentially impose an unreasonable or disproportionately large load on our servers or network infrastructure;</li>
				<li>bypass any robot exclusion headers or other measures we take to restrict access to the Service, or use any software, technology, or device to scrape, spider, or crawl the Service or harvest or manipulate data;</li>
				<li>circumvent, disable or otherwise interfere with any security-related features of the Service or features that prevent or restrict use or copying of content, or enforce limitations on use of the Service or the content accessible via the Service; or</li>
				<li>Publish or link to malicious content of any sort, including that intended to damage or disrupt another user’s browser or computer.</li>
			</ul>

			<h5>Account Termination</h5>
			<p>SaveDate reserves the right to terminate or suspend your account and access to the Service, in its discretion, for any violation of these Terms or for any other valid reason.</p>
			
			<h5>Dispute Resolution</h5>
			<p>Any disputes arising from or relating to the use of the Service will be resolved through binding arbitration in accordance with Indian laws and regulations. Users waive their right to initiate legal proceedings in court.</p>
		
			<h5>Limitation of Liability</h5>
			<p>SaveDate disclaims any liability for damages that may occur due to your use of the Service during the free trial period. You use the Service at your own risk.</p>
			
			<h5>Posting and Conduct Restrictions</h5>
			<p>When you create your personalized account on the Service, you are solely responsible for the User Content that you post, upload, link to, or otherwise make available via the Service. You agree that we are only acting as a passive conduit for your online distribution and publication of your User Content. However, the Company reserves the right to remove any User Content from the Service at its sole discretion.</p>
			<p>We grant you permission to use and access the Service, subject to the following express conditions surrounding User Content. You agree that failure to adhere to any of these conditions constitutes a material breach of these Terms.</p>
			<p>By transmitting and submitting any User Content while using the Service, you agree as follows:</p>

			<ul>
				<li>You are solely responsible for your account and the activity that occurs while signed in to or while using your account.</li>
				<li>You will not post information that is malicious, libelous, false, or inaccurate.</li>
				<li>You will not post any information that is abusive, threatening, obscene, defamatory, libelous, or racially, sexually, religiously, or otherwise objectionable and offensive.</li>
				<li>You retain all ownership rights in your User Content but are required to grant the following rights to the Site and users of the Service as set forth more fully under the "License Grant" and "Intellectual Property" provisions below: When you upload or post User Content to the Site or the Service, you grant to the Site a worldwide, non-exclusive, royalty-free, transferable license to use, reproduce, distribute, prepare derivative works of, display, and perform that Content in connection with the provision of the Service; and you grant to each user of the Service, a worldwide, non-exclusive, royalty-free license to access your User Content through the Service, and to use, reproduce, distribute, prepare derivative works of, display and perform such Content to the extent permitted by the Service and under these Terms of Use.</li>
				<li>You will not submit content that is copyrighted or subject to third-party proprietary rights, including privacy, publicity, trade secret, or others unless you are the owner of such rights or have the appropriate permission from their rightful owner to specifically submit such content.</li>
				<li>You hereby agree that we have the right to determine whether your User Content submissions are appropriate and comply with these Terms of Service, remove any and/or all of your submissions, and terminate your account with or without prior notice.</li>
			</ul>

			<p>You understand and agree that any liability, loss, or damage that occurs as a result of the use of any User Content that you make available or access through your use of the Service is solely your responsibility. The Site is not responsible for any public display or misuse of your User Content.</p>
			<p>The Site does not, and cannot, pre-screen or monitor all User Content. However, at our discretion, we, or technology we employ, may monitor and/or record your interactions with the Service or with other Users.</p>

			<h5>Online Content Disclaimer</h5>
			<p>Opinions, advice, statements, offers, or other information or content made available through the Service, but not directly by the Site, are those of their respective authors and should not necessarily be relied upon. Such authors are solely responsible for such content.</p>
			<p>We do not guarantee the accuracy, completeness, or usefulness of any information on the Site or the Service, nor do we adopt, endorse, or take responsibility for the accuracy or reliability of any opinion, advice, or statement made by other parties. We take no responsibility and assume no liability for any User Content that you or any other user or third party posts or sends via the Service. Under no circumstances will we be responsible for any loss or damage resulting from anyone's reliance on information or other content posted on the Service or transmitted to users.</p>
			<p>Though we strive to enforce these Terms of Use, you may be exposed to User Content that is inaccurate or objectionable when you use or access the Site or the Service. We reserve the right but have no obligation to monitor the materials posted in the public areas of the Site or the Service or to limit or deny a user's access to the Service or take other appropriate action if a user violates these Terms of Use or engages in any activity that violates the rights of any person or entity or which we deem unlawful, offensive, abusive, harmful, or malicious. The Company shall have the right to remove any material that in its sole opinion violates or is alleged to violate the law or this agreement or which might be offensive or that might violate the rights, harm, or threaten the safety of users or others. Unauthorized use may result in criminal and/or civil prosecution under Federal, State, and local law. If you become aware of misuse of our Service or violation of these Terms of Use, please contact us at <a href="mailto:info@savedate.me">info@savedate.me</a>.</p>

			<h5>Acceptance of Terms</h5>
			<p>The SaveDate is an Internet-based entertainment Invitation Portal and Mobile App owned and operated by Ooly Web Studio, Kerala, India.</p>
			<p>This User(s) Agreement ("Agreement") sets out the terms and conditions on which SaveDate shall provide the Services to the User(s) through the Website/Mobile App. In addition to this Agreement and depending on the Services opted for by the User(s), the User(s) shall be required to read and accept the relevant Terms of Service (TOS) that may be in place for such Service on SaveDate of relevant Photographers or under extant law and customs, which may be updated or modified by SaveDate or the Competent Authorities from time to time. Such TOS shall be deemed to be a part of this Agreement, and in the event of a conflict between such TOS and this Agreement, the terms of this Agreement shall prevail.</p>
			<p>Use of the SaveDate is offered to the User(s) conditional on acceptance without modification of all the terms, conditions, and notices</p>

			<h5>Limited User(S)</h5>
			<p>The User(s) acknowledges and agrees not to engage in the sale, trade, or resale of any part of the Service for commercial purposes. SaveDate's Services are intended exclusively for personal use and not for commercial exploitation.</p>
			<p>The User(s) further agrees not to alter, replicate, distribute, transmit, display, perform, reproduce, publish, license, create derivative works from, transfer, or sell any information, software, products, or services obtained from SaveDate. It is explicitly prohibited to engage in wholesale reproduction, copying content for commercial or non-commercial purposes, or making unwarranted modifications to data and information found on the Website.</p>

			<h5>Disclaimer Of Warranties / Limitation Of Liability</h5>
			<ul>
				<li>SaveDate strives to ensure the accuracy of the information on the website. However, SaveDate does not guarantee or make any representations regarding the quality, accuracy, or completeness of any data or information available for user interaction.</li>
				<li>SaveDate does not warrant, either explicitly or implicitly, the quality or accuracy of the SaveDate Service and its contents. It disclaims any warranties related to fitness for a specific purpose or the efficacy and accuracy of event invitation services provided by photographers or other institutions. SaveDate bears no responsibility for any loss, whether direct or consequential, experienced by any User(s) or any other individuals due to the use of the website's information.</li>
				<li>As an online event invitation platform, SaveDate assumes no liability for the services provided by Service Providers. SaveDate cannot control or prevent changes in descriptions based on information provided by photographers or other institutions.</li>
				<li>SaveDate does not endorse any advertisers on its website or mobile app. Users are encouraged to independently verify the accuracy of all information before relying on it.</li>
				<li>SaveDate shall not be liable for any direct, indirect, punitive, incidental, special, or consequential damages resulting from various factors, including but not limited to the use of the Services, procurement of substitute goods or services, unauthorized access or alterations to user transmissions or data, and other matters related to the Services. SaveDate will not be held responsible for any non-availability of the website/mobile app due to maintenance operations, technical issues, or any other reasons beyond its control.</li>
				<li>SaveDate is not liable for credit card fraud, with the responsibility for fraudulent card use falling on the user. Any request for order cancellations after placement will not be entertained. Additional fees for redelivery due to user errors in the provided name or address may be claimed.</li>
				<li>The User agrees to provide accurate information, which may be confirmed and validated by photographers. In case the User's details are found to be inaccurate, photographers have the right to reject registration and disallow the User from using their services.</li>
				<li>Users understand that downloading material or data from SaveDate is at their own discretion and risk. Users are solely responsible for any damage to their computer systems or data loss resulting from downloaded material or data. These limitations and disclaimers apply regardless of the cause of action.</li>
				<li>SaveDate's maximum liability, under any circumstances, for any services offered on the site is limited to the refund of the total amount received from the User(s) for those services, minus any applicable cancellation or refund charges. SaveDate's liability does not include any consequential losses, damages, or additional expenses.</li>
				<li>By submitting photos to SaveDate, the User grants SaveDate and its affiliates the right to use those photos for advertising, marketing, promotion, and other commercial purposes.</li>
				<li>Users must be at least 18 years of age to use SaveDate.</li>
				<li>Users are responsible for uploading only relevant and appropriate images that comply with intellectual property, privacy, and other applicable laws.</li>
				<li>Users must have the copyright to the images they upload. Images infringing on the copyright or trademarks of others will not be permitted.</li>
				<li>Users uploading images should not contain objectionable content, including nudity, violence, offensive or illegal material, or advertisements.</li>
				<li>By uploading an image, the User grants SaveDate and its licensees the right to use the image for various purposes.</li>
				<li>Users indemnify SaveDate from any liability resulting from copyright breaches of images uploaded on the website.</li>
				<li>SaveDate is not obligated to display or use User-submitted images.</li>
				<li>SaveDate reserves the right to alter these terms at any time without prior notice and to terminate the Photo Upload Service without notice.</li>
				<li>SaveDate also reserves the right to reject and remove any uploaded photo from display without prior notice.</li>
				<li>Users acknowledge that uploaded images do not infringe on any copyrights, trademarks, or other rights.</li>	
				<li>Users understand that SaveDate is not responsible for the misuse or abuse of any images uploaded to the site.</li>
				<li>SaveDate is not responsible for copyright images uploaded on the website and may remove them without notice.</li>
				<li>SaveDate reserves the right to delete content, including images and to cancel or remove services or user accounts without prior notice or explanation.</li>
				<li>SaveDate makes no warranties regarding user-submitted images or user information and disclaims all liability related to them.</li>
				<li>SaveDate is not liable for any damages or losses arising from the use of the website or its content.</li>
				<li>Users agree that SaveDate is not responsible for the misuse of event invitation accounts or websites/portfolios created from reseller accounts.</li>
				<li>SaveDate reserves the right to update, edit, create, or remove any part of the website without prior notice.</li>
			</ul>

			<h5>Links To Third Party Sites</h5>
			<p>SaveDate may feature links to external websites ("Linked Sites"). These Linked Sites are not within the control of SaveDate, and SaveDate bears no responsibility for the content found on any Linked Site. This includes links within a Linked Site or any modifications or updates to a Linked Site. SaveDate does not assume responsibility for any form of data or communication received by Users from any Linked Site. These links are provided solely for the convenience of Users, and their inclusion does not signify SaveDate's endorsement of the Linked Sites or any affiliation with their operators, owners, legal heirs, or assigns.</p>
			<p>SaveDate is not liable for any inaccuracies, omissions, or representations on any Linked Site. SaveDate does not endorse any advertisers on Linked Sites. Users are advised to independently verify the accuracy of information on Linked Sites before relying on it.</p>

			<h5>Prohibition Against Unlawful Use</h5>
			<p>As a condition of using SaveDate, Users warrant that they will not employ the platform for unlawful or illegal purposes, whether prohibited by law in force within or outside India, or by the terms of this Agreement and/or the TOS, including both explicit and implicit restrictions. Moreover, the Website must not be used in a manner that could harm, disable, overload, or disrupt it, or interfere with the enjoyment of the Website by other parties. Users must refrain from attempting to access materials or information through methods not intentionally provided or made available through SaveDate.</p>

			<h5>Use Of Communication Services</h5>
			<p>SaveDate may offer various communication services, including but not limited to email, chat, bulletin boards, recommendations, news groups, forums, communities, personal web pages, calendars, and other messaging features (collectively referred to as "Communication Services"). Users agree to use these Communication Services solely for posting, sending, and receiving messages and content that are appropriate and relevant to the specific Communication Service. As an illustrative list and not an exhaustive one, Users commit to the following when utilizing a Communication Service:</p>
			<ul>
				<li>Users will not defame, abuse, harass, stalk, threaten, or infringe upon the legal rights of others.</li>
				<li>Users will not upload files containing software or materials protected by intellectual property laws unless they own the rights or have obtained all necessary consents.</li>
				<li>Users will not upload or distribute files containing viruses, corrupted files, or any other software that could harm SaveDate's operations or another user's computer.</li>
				<li>Users will not use Communication Services for business purposes such as advertising or buying/selling goods and services unless expressly permitted by the specific Communication Service.</li>
				<li>Users will not conduct or forward surveys, contests, pyramid schemes, or chain letters.</li>
				<li>Users will not download any file from a Communication Service if they know or should reasonably know that it cannot be legally distributed in that manner.</li>
				<li>Users will not falsify or delete author attributions, legal notices, or proprietary labels of the origin or source of uploaded software or materials.</li>
				<li>Users will comply with any code of conduct or guidelines specific to a particular Communication Service.</li>
				<li>Users will adhere to all applicable laws and regulations in force in India or any other relevant jurisdiction.</li>
				<li>Users will abide by the terms and conditions outlined in this Agreement or any additional terms and conditions associated with the use of SaveDate.</li>
			</ul>

			<p>SaveDate is not obligated to actively monitor Communication Services but retains the right to review materials posted through these services and remove any content at its discretion. SaveDate may terminate a user's access to Communication Services, in whole or in part, without notice and for any reason.</p>
			<p>SaveDate also reserves the right to disclose information as required to comply with applicable laws, regulations, legal processes, government requests, or to edit, decline to post, or remove any content at its sole discretion.</p>
			<p>SaveDate does not control or endorse the content, messages, or information within Communication Services. Consequently, SaveDate disclaims any liability for actions resulting from a user's participation in these services. Materials uploaded to Communication Services may be subject to usage, reproduction, and dissemination limitations, and users are responsible for adhering to these restrictions.</p>

			<h5>Termination/Access Restriction</h5>
			<p>SaveDate retains the sole discretion to terminate access to the website/mobile app and its associated services, or any part thereof, at any time without prior notice.</p>

			<h5>Fees Payment</h5>
			<p>As of now, SaveDate does not impose any charges for account creation or the utilization of our services. However, it is important to note that SaveDate cannot be held responsible for any misuse of our platform.</p>
			<p>SaveDate retains the right to introduce listing fees for specific listings and transaction fees for completed transactions facilitated through the SaveDate service. Furthermore, SaveDate reserves the prerogative to modify these fees at its discretion, with or without prior notice.</p>
			<p>Users are responsible for settling all pertinent charges, fees, duties, taxes, levies, and assessments associated with their use of the SaveDate service.</p>

			<h5>User(S)'s Obligations And User(S) Account</h5>
			<p>By using SaveDate, the User(s) affirm that they are of legal age to enter into a binding contract and are not prohibited from using SaveDate and its services by the laws of India or any other applicable legal jurisdiction.</p>
			<p>To access SaveDate's services, the User(s) must possess and maintain, at their own expense: (a) the necessary equipment, including a computer and modem, to connect to SaveDate and its services, and (b) access to the World Wide Web/Mobile App. Any third-party fees, such as airtime or internet service provider charges, incurred for accessing the services are the sole responsibility of the User(s).</p>
			<p>The User(s) acknowledges that SaveDate may send service announcements and administrative messages as part of its services. SaveDate provides its services on an "as is" basis and is not liable for the deletion, misdelivery, or failure to store User(s) communications or personalized settings.</p>
			<p>User registration on SaveDate is optional. If the User(s) chooses to register, they will receive a User(s) ID and Password upon completing the registration process. The User(s) are responsible for maintaining the confidentiality of their User(s) ID and Password and are fully accountable for all activities conducted using their password or User(s) ID. Using another party's User(s) ID and Password without proper authorization is strictly prohibited. Users are also responsible for the security of their password and all transactions conducted using their password via our service. The User(s) confirm their status as the authorized holder of the credit card or original account used in transactions through SaveDate, and SaveDate bears no responsibility for financial losses resulting from the misuse of User(s) ID, Password, or credit card number.</p>
			<p>Users must immediately notify SaveDate of any unauthorized use of their User(s) ID or Password and ensure they log off at the end of each session on SaveDate. Failure to comply with this requirement will not hold SaveDate responsible for any direct or indirect loss or damage.</p>
			<p>Users must provide accurate and complete information about themselves and any beneficiaries as prompted by the registration form. This information should be updated promptly to maintain accuracy. SaveDate reserves the right to suspend or terminate a User(s)'s registration and refuse any current or future use of SaveDate if provided information is found to be untrue, inaccurate, not current, or incomplete.</p>
			<p>Furthermore, Users grant SaveDate the right to disclose Registration Data to third parties when necessary to perform its services.</p>

			<h5>Breach</h5>
			<p>SaveDate may take various actions, including limiting User(s) activity, ending their listings, issuing warnings to other User(s), temporarily or indefinitely suspending or terminating User(s) registration, and refusing access to SaveDate, if:</p>
			<ul>
				<li>The User(s) violates this agreement, the TOS, or related documents.</li>
				<li>SaveDate cannot verify or authenticate information provided by the User(s).</li>
				<li>SaveDate believes that the User(s)'s actions may infringe on third-party rights, violate applicable laws, or result in liability for the User(s), other User(s) of the website, or SaveDate.</li>
			</ul>
			<p>SaveDate may reinstate suspended User(s) at its sole discretion. However, indefinitely suspended User(s) are prohibited from registering or attempting to register with SaveDate or using the website until reinstated. Despite this, if a User(s) breaches this agreement, the TOS, or related documents, SaveDate reserves the right to seek recovery of any outstanding amounts owed and take legal action as it deems necessary.</p>

			<h5>Proprietary Rights</h5>
			<p>SaveDate may provide Users with content such as sound, photographs, graphics, video, or other materials found in sponsored advertisements or information. This content may be safeguarded by copyrights, trademarks, or other intellectual property rights and laws. Users may utilize this material exclusively as explicitly permitted by SaveDate, refraining from copying, transmitting, or creating derivative works from such material without express authorization from SaveDate.</p>
			<p>Users acknowledge and agree not to upload, post, reproduce, or distribute any content on SaveDate that is protected by copyright or other proprietary rights of a third party without securing the owner's permission. Any copyrighted or proprietary content shared on SaveDate with the owner's consent must include the appropriate copyright or proprietary rights notice. The unauthorized submission or distribution of copyrighted or proprietary content is illegal and may result in personal liability or criminal prosecution.</p>

			<h5>Relationship</h5>
			<p>None of the provisions within this Agreement, terms and conditions, notices, or the right to use SaveDate by Users, whether found in this Agreement or any other section or pages of SaveDate and/or Linked Sites, should be construed as establishing a partnership between Users and SaveDate. Neither party shall have the authority to bind or be considered the agent of the other in any capacity. However, if Users authorize SaveDate and its agents to access third-party sites for retrieving information on their behalf, such authorization will appoint SaveDate and its agents as their representatives for this purpose.</p>

			<h5>Headings</h5>
			<p>The headings and subheadings provided here are for convenience and identification purposes only and are not meant to define, interpret, describe, or limit the scope, extent, or intent of this Agreement, the TOS, or the right to use SaveDate. These headings do not restrict the content or terms in any manner.</p>

			<h5>Interpretation Of Number And Genders</h5>
			<p>The terms and conditions outlined herein apply equally to both singular and plural forms of the defined terms. In cases where context dictates, any pronoun should be understood to encompass both masculine and feminine forms. The words "include," "includes," and "including" should be construed to mean "without limitation" unless otherwise required by the context. Unless specified otherwise, terms such as "herein," "hereof," "hereto," "hereunder," and similar expressions refer to this Agreement as a whole.</p>

			<h5>Indemnification</h5>
			<p>The User(s) hereby agrees to indemnify, defend, and hold harmless SaveDate against any and all losses, liabilities, claims, damages, costs, and expenses, including legal fees and disbursements, along with interest charges that may be applicable, arising from or related to any breach or failure to perform any representation, warranty, covenant, or agreement made by the User(s) under this Agreement and/or the TOS.</p>

			<h5>Severability</h5>
			<p>In the event that any provision of this Agreement is determined to be invalid or unenforceable, whether in whole or in part, such invalidity or unenforceability shall only affect the specific provision or portion of the provision in question. The remaining parts of that provision and all other provisions of this Agreement shall remain in full force and effect.</p>

			<h5>Termination Of Agreement And Services</h5>
			<p>Either the User(s) or SaveDate has the right to terminate this Agreement and any Service with or without cause, and such termination shall be effective immediately.</p>
			<p>SaveDate reserves the right to immediately terminate the User(s)'s User(s) ID and access to the Website/Mobile App/Services under specific circumstances and without prior notice. Grounds for termination may include, but are not limited to, the User(s)'s breach of this Agreement or the TOS, requests from law enforcement or government agencies, User(s)'s requests, or non-payment of fees related to the Services, as outlined in the applicable TOS.</p>
			<p>This Agreement may be terminated by either the User(s) or SaveDate by providing written notice to the other party. SaveDate shall not be liable to the User(s) or any third party for the termination of any Service. If the User(s) disagrees with any terms and conditions in this Agreement, any TOS, or is dissatisfied with the Service in any manner, their sole recourse is to immediately: (a) cease using the Website/Mobile App/Service, and (b) inform SaveDate of their discontinuance.</p>
			<p>Upon the termination of the Service, the User(s) will lose the right to use the Website/Mobile App/Services and associated software. Thereafter, the User(s) shall have no rights, and SaveDate shall have no obligations to complete any unfinished tasks or forward any unread or unsent messages to the User(s) or any third party. Once the User(s)'s registration or the Services are terminated, canceled, or suspended, any data stored on SaveDate may not be retrievable.			</p>

			<h5>Notices</h5>
			<p>All notices and communications, including those regarding changes in the TOS, Service, termination of Service, and other matters, must be in written form in English. Such notices are considered delivered when sent by personal delivery, commercial messenger, courier service, or when mailed via registered or certified mail with a return receipt request, or when transmitted via email or facsimile with acknowledgment of complete transmission. Notices should be addressed as follows:</p>
			<p>For SaveDate, at Ooly Web Studio 42/2917G, Arakkakadavu Road, Alinchuvadu, Kochi - 682028, at the address posted on the Website.</p>
			<p>For non-registered Users, at the communication and/or email address provided in the application form for availing SaveDate Services.</p>
			<p>For registered Users, at the communication and/or email address specified in the registration form. Notice is deemed served 48 hours after being sent, dispatched, or displayed unless it is revealed to the sending party that the email address is invalid when notice is sent by email.</p>

			<h5>Governing Law</h5>
			<p>This Agreement and each TOS shall be subject to the laws of India, and the Courts in Kochi shall have jurisdiction to adjudicate any disputes arising from the issues and subject matter pertaining to this Agreement.</p>

			<h5>Warranty Disclaimer</h5>
			<p>THE SERVICE IS PROVIDED "AS IS," WITHOUT ANY WARRANTY OF ANY KIND. WE EXPRESSLY DISCLAIM ALL WARRANTIES, WHETHER EXPRESS, IMPLIED, OR STATUTORY, INCLUDING, BUT NOT LIMITED TO, WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, TITLE, SECURITY, ACCURACY, AND NON-INFRINGEMENT. WE MAKE NO WARRANTY OR REPRESENTATION THAT ACCESS TO OR OPERATION OF THE SERVICE WILL BE UNINTERRUPTED OR ERROR-FREE. YOU BEAR FULL RESPONSIBILITY AND ASSUME THE RISK OF LOSS RESULTING FROM YOUR DOWNLOADING AND/OR USE OF FILES, INFORMATION, CONTENT, OR OTHER MATERIAL OBTAINED FROM THE SERVICE. SOME JURISDICTIONS RESTRICT OR PROHIBIT DISCLAIMERS OF WARRANTY, SO THIS PROVISION MAY NOT APPLY TO YOU.</p>

			<h5>Limitation Of Damages; Release</h5>
			<p>TO THE EXTENT PERMITTED BY APPLICABLE LAW, UNDER NO CIRCUMSTANCES SHALL THE SITE, THE SERVICE, ITS AFFILIATES, DIRECTORS, EMPLOYEES, LICENSORS, OR PARTNERS BE LIABLE TO YOU FOR ANY LOSS OF PROFITS, USE, OR DATA, OR FOR ANY INCIDENTAL, INDIRECT, SPECIAL, CONSEQUENTIAL, OR EXEMPLARY DAMAGES, ARISING IN ANY WAY, INCLUDING BUT NOT LIMITED TO: (A) THE USE, DISCLOSURE, OR DISPLAY OF YOUR USER CONTENT; (B) YOUR USE OR INABILITY TO USE THE SERVICE; (C) THE SERVICE GENERALLY OR THE SOFTWARE OR SYSTEMS THAT ENABLE THE SERVICE; OR (D) ANY OTHER INTERACTIONS WITH YOU OR WITH ANY OTHER USER OF THE SERVICE, WHETHER BASED ON WARRANTY, CONTRACT, TORT (INCLUDING NEGLIGENCE), OR ANY OTHER LEGAL THEORY, EVEN IF WE HAVE BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. IN THE EVENT THAT A REMEDY SET FORTH HEREIN IS FOUND TO HAVE FAILED IN ITS ESSENTIAL PURPOSE, SOME JURISDICTIONS LIMIT OR DO NOT ALLOW LIMITATIONS OF LIABILITY, SO THIS PROVISION MAY NOT APPLY TO YOU.</p>

			<h5>Modification Of Terms Of Use</h5>
			<p>We have the right to modify these Terms of Use at any time, and any such modifications will be reflected in the updated Terms of Use. It is your responsibility to periodically check the Site for any changes in this agreement. By continuing to use the Site or the Service, you indicate your acceptance of these revised Terms of Use. We will make reasonable efforts to notify you of substantial changes to the Terms by posting a notice on our homepage and/or sending an email to the address you provided during registration. For this reason, it is essential to maintain your contact and profile information up-to-date. Any alterations to these Terms (except as outlined in this paragraph) or waivers of our rights under this agreement will not be valid or effective unless documented in a written agreement bearing the physical signature of one of our officers. No claimed waiver or modification of this agreement by us through telephonic or email communications will be valid.</p>

			<h5>General Terms</h5>
			<p>If any part of this Terms of Use agreement is deemed invalid or unenforceable, that part will be interpreted to be in accordance with applicable law, while the remaining portions of the agreement will continue to be in full force and effect. Our failure to enforce any provision of this agreement does not constitute a waiver of our right to enforce that provision. Our rights under this agreement persist even after any transfer or termination of this agreement.</p>
			<p>You acknowledge that you have read these Terms of Use, understand their contents, and agree to be bound by these terms and conditions. You further recognize that these Terms of Use, along with the <a href="">Privacy Policy</a>, represent the comprehensive and exclusive understanding between us, supplanting any prior written or oral agreements or communications concerning the subject matter of this Agreement.</p>


			<h5>Modification of Terms</h5>
			<p>SaveDate may update or modify these Terms. We will notify users of significant changes. It is your responsibility to review these Terms periodically.</p>
		
			<h5>Contact Us</h5>
			<p>If you have any questions or concerns regarding these Terms, please contact us at <a href="mailto:info@savedate.me">info@savedate.me</a>.</p>
            </Modal.Body>
      </Modal>
  )
}

export default TermsAndConditions