import React from 'react'
import './Css/BannerImageAlert.css';
import { Modal} from 'react-bootstrap';
function DeleteConfirmationModal(props) {
  return (
    <Modal
    show={props.show}
    size="sm"
    onHide={props.close}
    centered
  >
    <Modal.Body className="alert-boxwrap updatecancel-btn">
      <button type="button" className="close" onClick={props.close}>
        <i className="fas fa-times"></i>
      </button>
      <i class="fas fa-exclamation-circle"></i>
      <h5>Warning</h5>
      <p>{props.message}</p>
      <div className="row">
        <div className="col-6">
          <button className="cancelbtn" onClick={props.close}>Cancel</button>
        </div>
        <div className="col-6">
          <button className="updatebtn" onClick={() => { props.Delete(props.Id); props.close(); }}>{props.From === 'Header' ? 'Deactivate' : 'Delete'}</button>
        </div>
      </div>
    </Modal.Body>
  </Modal> 
  )
}

export default DeleteConfirmationModal