// export const BaseUrl="http://localhost:58931/api"
// export const Imageurl="http://localhost:58931"
// export const PreviewUrl="http://localhost:3000"
// export const FrontEndVersion='1.0.5'

//Test
export const BaseUrl="https://savedate.initstore.com/api"
export const Imageurl="https://savedate.initstore.com"
export const PreviewUrl="https://savedate.initstore.com"
export const FrontEndVersion='1.0.5'

// Sopanam
// export const BaseUrl="https://savedate.sopanam.in/api"
// export const Imageurl="https://savedate.sopanam.in"
// export const PreviewUrl='https://savedateevents.initstore.com'
// export const FrontEndVersion='1.0.0'

// Live
// export const BaseUrl="https://savedate.me/api"
// export const Imageurl="https://savedate.me"
// export const PreviewUrl='https://event.savedate.me'
// export const FrontEndVersion='1.0.5'


// // Live
// export const BaseUrl="https://connect.savedate.me/api"
// export const Imageurl="https://connect.savedate.me"
// export const PreviewUrl='https://event.savedate.me'
// export const FrontEndVersion='1.0.5'